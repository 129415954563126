.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.nav-link {
  font-size: 15px !important;
  font-weight: 300 !important;
  color: white !important;
  font-family: "Open Sans", sans-serif !important;
  padding: 25px !important;
}
.nav-link:hover {
  color: #c8d6e5 !important;
  border-radius: 3px;
}
.nav-link::after {
  content: "";
  display: block;
  width: 0;
  height: 3px;
}
.nav-link:hover::after {
  width: 100%;
}
.revi-im img {
  width: 86%;
  height: 69%;
  object-fit: cover;
  border-radius: 10px;
  clip-path: polygon(50% 0px, 100% 50%, 50% 100%, 0px 50%);
  text-align: center;
  margin-left: 55px;
}
.carduu {
  width: "80%" !important;
  background-color: #c8d6e5 !important;
}
.carduu:hover {
  background-color: #010161 !important;
  cursor: pointer !important;
}
.containerrrr {
  height: 22rem;
  overflow: hidden;
  position: relative;
}
.containerrrr img {
  width: 100%;
  transition: all 0.5s;
}
.caption {
  position: absolute;
  top: 215px;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #010161cd;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
  flex-direction: column;
  opacity: 0;
  transition: all 0.5s;
}
.caption h1 {
  font-weight: 500;
  letter-spacing: 2px;
  font-family: "Poppins";
  font-size: 18px;
  text-align: center;
  margin-bottom: 4px;
}
.caption p {
  font-size: 10px;
  margin-bottom: 259px !important;
}
.containerrrr:hover .caption {
  opacity: 1;
  background-color: #010161cd;
}
.carduu {
  color: #010161;
  transition: all 0.5s ease-out;
  background: linear-gradient(to right, #010161 50%, #fff 50%);
  background-size: 200% 100%;
  background-position: right;
}
.carduu:hover {
  color: white !important;
  background-position: left;
}
.back-to-top-button {
  display: none;
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #00008e;
  color: #010161;
  cursor: pointer;
  padding: 11px;
  transition: opacity 0.3s ease-in-out;
  font-weight: 500;
  font-size: 18px;
}

.back-to-top-button.visible {
  display: block;
}
.btn-blue {
  background-image: linear-gradient(to right, #010161, #00008e);
  padding: 14px;
  color: white;
}
/* .homee {
  padding: 3rem;
} */
.home1 {
  padding: 3rem;
  padding-left: 15px;
}
.home2 {
  margin-top: 3rem;
}
.whole {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 3rem;
  padding-top: 3rem;
}
.about4 {
  padding: 3rem;
}
.whole1 {
  padding: 3rem;
}
.contactuu {
  padding: 3rem;
  text-align: center;
  justify-content: center;
  padding: 0.5rem;
}
/* watsapp and call icon css starts here */

.scroll_top_button {
  height: 50px;
  width: 50px;
  background-color: #ed6a19;
  position: fixed;
  bottom: 18px;
  right: 10px;
  z-index: 9999;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.scroll_top_button1 {
  height: 50px;
  width: 50px;
  /* background-color: #ed6a19; */
  position: fixed;
  bottom: 73px;
  right: 10px;
  z-index: 9999;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.scroll_top_button:hover {
  background: #125173;
}
.scroll_top_button1:hover {
  background: #125173;
}

.scroll_top_button {
  scroll-behavior: smooth !important;
}
.scroll_top_button1 {
  scroll-behavior: smooth !important;
}

/* svg{
        fill: white;

    } */

.scroll_top_button.active_0.k9 {
  /* top: 580px; */
  opacity: 1;
  visibility: visible;
}
.scroll_top_button1.active_01.k9 {
  /* top: 580px; */
  opacity: 1;
  visibility: visible;
}

html.smooth {
  scroll-behavior: smooth !important;
}
/* watsapp and call icon css ends here */
@media only screen and (min-width: 321px) and (max-width: 374px) {
  .hoome .col-md-2 {
    display: block !important;
    width: 100% !important;
  }
  .top .col-md-3 {
    display: block !important;
    width: 100% !important;
  }
  .top .col-md-2 {
    width: 100% !important;
    padding: 20px !important;
  }
  .about {
    position: static !important;
    width: 100% !important;
    margin-left: unset !important;
    padding: 30px !important;
  }
  .aboutimg {
    position: static !important;
    width: 100% !important;
    height: 90% !important;
  }
  .vision {
    padding: unset !important;
  }
  .visionimg img {
    width: 100% !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .mission {
    padding: unset !important;
  }
  .missionimg img {
    width: 100% !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .button {
    display: flex !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .button1 {
    display: flex !important;
    text-align: end !important;
    justify-content: end !important;
    margin-top: -4.6rem !important;
  }
  .home .carousal-item img {
    margin-left: unset !important;
  }
  .home .image {
    height: 250px !important;
  }
  .carousel-caption {
    margin-top: unset !important;
  }
  .home .carousal-item h6 {
    font-size: 8px !important;
  }

  .home .carousel-caption h3 {
    font-size: 20px !important;
  }
  .topone {
    display: none !important;
  }
  .home {
    height: unset !important;
    left: unset !important;
    position: static !important;
  }
  .homee {
    padding: 1rem !important;
  }
  .home1 {
    padding: 1.5rem !important;
  }
  .home2 {
    padding: 2rem !important;
    margin-top: unset !important;
  }
  .home3 img {
    width: 100% !important;
    height: unset !important;
  }
  .home4 {
    position: static !important;
  }
  .carduu {
    margin-left: 0px !important;
  }
  .home9 {
    position: static !important;
    margin-left: 0px !important;
    padding: 20px !important;
  }
  .home10 img {
    position: static !important;
    width: 100% !important;
  }
  .navbar-nav {
    height: 27.7rem !important;
  }
  .navvv {
    margin-left: unset !important;
  }
  .carousel-caption {
    margin-top: 3rem;
  }
  .buttonnnn {
    padding: 5px !important;
    font-size: 10px !important;
  }
  .home1 h5 {
    font-size: 1rem !important;
  }
  .home1 h3 {
    font-size: 2rem !important;
  }
  .home2 p {
    margin-top: unset !important;
  }
  .home2 a {
    float: unset !important;
  }
  .whole {
    padding: 16px !important;
  }
  .whole h5 {
    font-size: 1rem !important;
  }
  .whole h3 {
    font-size: 2rem !important;
  }
  .whole p {
    margin-top: unset !important;
  }
  .whole a {
    margin-right: unset !important;
    float: unset !important;
  }
  .aboutimg {
    height: unset !important;
  }
  .about1 {
    margin-top: unset !important;
  }
  .about2 {
    padding: 16px !important;
  }
  .about3 {
    padding: 16px !important;
  }
  .about4 {
    height: 100% !important;
    padding: 20px !important;
  }
  .contactimg {
    padding: unset !important;
  }
  .contact1 {
    padding: 6px !important;
  }
  .hom {
    margin-left: unset !important;
  }
  .navp {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .collll {
    position: static !important;
  }
}
@media only screen and (min-width: 375px) and (max-width: 424px) {
  .hoome .col-md-2 {
    display: block !important;
    width: 100% !important;
  }
  .top .col-md-3 {
    display: block !important;
    width: 100% !important;
  }
  .top .col-md-2 {
    width: 100% !important;
    padding: 20px !important;
  }
  .about {
    position: static !important;
    width: 100% !important;
    margin-left: unset !important;
    padding: 30px !important;
  }
  .aboutimg {
    position: static !important;
    width: 100% !important;
    height: 90% !important;
  }
  .vision {
    padding: unset !important;
  }
  .visionimg img {
    width: 100% !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .mission {
    padding: unset !important;
  }
  .missionimg img {
    width: 100% !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .button {
    display: flex !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .button1 {
    display: flex !important;
    text-align: end !important;
    justify-content: end !important;
    margin-top: -4.6rem !important;
  }
  .carousal-item img {
    height: unset !important;
    margin-left: unset !important;
  }
  .home .image {
    height: 250px !important;
  }
  .carousel-caption {
    margin-top: unset !important;
  }
  .home .carousal-item h6 {
    font-size: 8px !important;
  }

  .home .carousel-caption h3 {
    font-size: 20px !important;
  }
  .buttonnnn {
    padding: 5px !important;
    font-size: 10px !important;
  }
  .topone {
    display: none !important;
  }
  .home {
    height: unset !important;
    left: unset !important;
    position: static !important;
  }
  .homee {
    padding: unset !important;
  }
  .home1 {
    padding: 2rem !important;
  }
  .home2 {
    padding: 2rem !important;
    margin-top: unset !important;
  }
  .home3 img {
    width: 100% !important;
    height: unset !important;
  }
  .home4 {
    position: static !important;
  }
  .carduu {
    margin-left: 0px !important;
  }
  .home9 {
    position: static !important;
    margin-left: 0px !important;
    padding: 20px !important;
  }
  .home10 img {
    position: static !important;
    width: 100% !important;
  }
  .navbar-nav {
    height: 27.7rem !important;
  }
  .navvv {
    margin-left: unset !important;
  }
  .carousel-caption {
    margin-top: unset !important;
  }
  .home1 h5 {
    font-size: 1rem !important;
  }
  .home1 h3 {
    font-size: 2rem !important;
  }
  .home2 p {
    margin-top: unset !important;
  }
  .home2 a {
    float: unset !important;
  }
  .whole {
    padding: 16px !important;
  }
  .whole h5 {
    font-size: 1rem !important;
  }
  .whole h3 {
    font-size: 2rem !important;
  }
  .whole p {
    margin-top: unset !important;
  }
  .whole a {
    margin-right: unset !important;
    float: unset !important;
  }
  .aboutimg {
    height: unset !important;
  }
  .about1 {
    margin-top: unset !important;
  }
  .about2 {
    padding: 16px !important;
  }
  .about3 {
    padding: 16px !important;
  }
  .about4 {
    height: 100% !important;
    padding: 20px !important;
  }
  .contactimg {
    padding: unset !important;
  }
  .contact1 {
    padding: 6px !important;
  }
  .hom {
    margin-left: unset !important;
  }
  .navp {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .collll {
    position: static !important;
  }
}
@media only screen and (min-width: 425px) and (max-width: 480px) {
  .hoome .col-md-2 {
    display: block !important;
    width: 100% !important;
  }
  .top .col-md-3 {
    display: block !important;
    width: 100% !important;
  }
  .top .col-md-2 {
    width: 100% !important;
    padding: 20px !important;
  }
  .about {
    position: static !important;
    width: 100% !important;
    margin-left: unset !important;
    padding: 30px !important;
  }
  .aboutimg {
    position: static !important;
    width: 100% !important;
    height: 90% !important;
  }
  .vision {
    padding: unset !important;
  }
  .visionimg img {
    width: 100% !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .mission {
    padding: unset !important;
  }
  .missionimg img {
    width: 100% !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .button {
    display: flex !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .button1 {
    display: flex !important;
    text-align: end !important;
    justify-content: end !important;
    margin-top: -4.6rem !important;
  }

  .home .image {
    height: 250px !important;
  }
  .carousel-caption {
    margin-top: unset !important;
  }
  .home .carousal-item h6 {
    font-size: 8px !important;
  }

  .home .carousel-caption h3 {
    font-size: 20px !important;
  }
  .buttonnnn {
    padding: 5px !important;
    font-size: 10px !important;
  }
  .topone {
    display: none !important;
  }
  .home {
    height: unset !important;
    left: unset !important;
    position: static !important;
  }
  .homee {
    padding: unset !important;
  }
  .home1 {
    padding: 2rem !important;
  }
  .home2 {
    padding: 2rem !important;
    margin-top: unset !important;
  }
  .home3 img {
    width: 100% !important;
    height: unset !important;
  }
  .home4 {
    position: static !important;
  }
  .carduu {
    margin-left: 0px !important;
  }
  .home9 {
    position: static !important;
    margin-left: 0px !important;
    padding: 20px !important;
  }
  .home10 img {
    position: static !important;
    width: 100% !important;
  }
  .navbar-nav {
    height: 27.7rem !important;
  }
  .navvv {
    margin-left: unset !important;
  }
  .carousel-caption {
    margin-top: 3rem;
  }
  .home1 h5 {
    font-size: 1rem !important;
  }
  .home1 h3 {
    font-size: 2rem !important;
  }
  .home2 p {
    margin-top: unset !important;
  }
  .home2 a {
    float: unset !important;
  }
  .whole {
    padding: 16px !important;
  }
  .whole h5 {
    font-size: 1rem !important;
  }
  .whole h3 {
    font-size: 2rem !important;
  }
  .whole p {
    margin-top: unset !important;
  }
  .whole a {
    margin-right: unset !important;
    float: unset !important;
  }
  .aboutimg {
    height: unset !important;
  }
  .about1 {
    margin-top: unset !important;
  }
  .about2 {
    padding: 16px !important;
  }
  .about3 {
    padding: 16px !important;
  }
  .about4 {
    height: 100% !important;
    padding: 20px !important;
  }
  .contactimg {
    padding: unset !important;
  }
  .contact1 {
    padding: 6px !important;
  }
  .hom {
    margin-left: unset !important;
  }
  .navp {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .collll {
    position: static !important;
  }
}
@media only screen and (min-width: 481px) and (max-width: 600px) {
  .hoome .col-md-2 {
    display: block !important;
    width: 100% !important;
  }
  .top .col-md-3 {
    display: block !important;
    width: 100% !important;
  }
  .top .col-md-2 {
    width: 100% !important;
    padding: 20px !important;
  }
  .about {
    position: static !important;
    width: 100% !important;
    margin-left: unset !important;
    padding: 30px !important;
  }
  .aboutimg {
    position: static !important;
    width: 100% !important;
    height: 90% !important;
  }
  .vision {
    padding: unset !important;
  }
  .visionimg img {
    width: 100% !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .mission {
    padding: unset !important;
  }
  .missionimg img {
    width: 100% !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .button {
    display: flex !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .button1 {
    display: flex !important;
    text-align: end !important;
    justify-content: end !important;
    margin-top: -4.6rem !important;
  }
  .home .image {
    height: 250px !important;
  }
  .carousel-caption {
    margin-top: unset !important;
  }
  .home .carousal-item h6 {
    font-size: 8px !important;
  }

  .home .carousel-caption h3 {
    font-size: 20px !important;
  }
  .buttonnnn {
    padding: 5px !important;
    font-size: 10px !important;
  }
  .topone {
    display: none !important;
  }
  .home {
    height: unset !important;
    left: unset !important;
    position: static !important;
  }
  .homee {
    padding: unset !important;
  }
  .home1 {
    padding: 2rem !important;
  }
  .home2 {
    padding: 2rem !important;
    margin-top: unset !important;
  }
  .home3 img {
    width: 100% !important;
    height: unset !important;
  }
  .home4 {
    position: static !important;
  }
  .carduu {
    margin-left: 0px !important;
  }
  .home9 {
    position: static !important;
    margin-left: 0px !important;
    padding: 20px !important;
  }
  .home10 img {
    position: static !important;
    width: 100% !important;
  }
  .navbar-nav {
    height: 27.7rem !important;
  }
  .navvv {
    margin-left: unset !important;
  }
  .carousel-caption {
    margin-top: 3rem;
  }
  .home1 h5 {
    font-size: 1rem !important;
  }
  .home1 h3 {
    font-size: 2rem !important;
  }
  .home2 p {
    margin-top: unset !important;
  }
  .home2 a {
    float: unset !important;
  }
  .whole {
    padding: 16px !important;
  }
  .whole h5 {
    font-size: 1rem !important;
  }
  .whole h3 {
    font-size: 2rem !important;
  }
  .whole p {
    margin-top: unset !important;
  }
  .whole a {
    margin-right: unset !important;
    float: unset !important;
  }
  .aboutimg {
    height: unset !important;
  }
  .about1 {
    margin-top: unset !important;
  }
  .about2 {
    padding: 16px !important;
  }
  .about3 {
    padding: 16px !important;
  }
  .about4 {
    height: 100% !important;
    padding: 20px !important;
  }
  .contactimg {
    padding: unset !important;
  }
  .contact1 {
    padding: 6px !important;
  }
  .hom {
    margin-left: unset !important;
  }
  .navp {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .collll {
    position: static !important;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .hoome .col-md-2 {
    display: block !important;
    width: 100% !important;
  }
  .top .col-md-3 {
    display: block !important;
    width: 100% !important;
  }
  .top .col-md-2 {
    width: 100% !important;
    padding: 20px !important;
  }
  .about4 {
    height: 100% !important;
    padding: 20px !important;
  }
  .about {
    position: static !important;
    width: 100% !important;
    margin-left: unset !important;
    padding: 30px !important;
  }
  .aboutimg {
    position: static !important;
    width: 100% !important;
    height: 90% !important;
  }
  /* .aboutimg1{
    position:absolute !important;

    margin-top: 3rem !important;
  } */
  .vision {
    padding: unset !important;
  }
  .visionimg img {
    width: 100% !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .mission {
    padding: unset !important;
  }
  .missionimg img {
    width: 100% !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .button {
    display: flex !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .button1 {
    display: flex !important;
    text-align: end !important;
    justify-content: end !important;
  }
  .hom {
    margin-left: unset !important;
  }
  .home .image {
    height: 314px !important;
  }
  .carousel-caption {
    margin-top: unset !important;
  }
  .home .carousal-item h6 {
    font-size: 8px !important;
  }

  .home .carousel-caption h3 {
    font-size: 20px !important;
  }
  .buttonnnn {
    padding: 5px !important;
    font-size: 10px !important;
  }
  .topone {
    display: none !important;
  }
  .home {
    height: unset !important;
    left: unset !important;
    position: static !important;
  }
  .homee {
    padding: unset !important;
  }
  .home1 {
    padding: 2rem !important;
  }
  .home2 {
    padding: 2rem !important;
    margin-top: unset !important;
  }
  .home3 img {
    width: 75% !important;
    height: unset !important;
  }
  .home4 {
    position: absolute !important;
    top: 103px !important;
    left: 73px !important;
  }
  .collll {
    padding: 31px !important;
  }
  .collll p {
    font-size: 1.2rem !important;
  }
  .carduu {
    width: 28% !important;
    margin-left: 0px !important;
  }
  .home9 {
    position: static !important;
    margin-left: 0px !important;
    padding: 20px !important;
  }
  .home10 img {
    position: absolute !important;
    top: 6rem !important;
    left: 17.5rem !important;
    width: 63% !important;
  }
  .navbar-nav {
    height: 27.7rem !important;
  }
  .navvv {
    margin-left: unset !important;
  }
  .carousel-caption {
    margin-top: 3rem;
  }
  .home1 h5 {
    font-size: 1rem !important;
  }
  .home1 h3 {
    font-size: 2rem !important;
  }
  .home2 p {
    margin-top: unset !important;
    font-size: 14px !important;
  }
  .home2 a {
    float: unset !important;
  }
  .whole {
    padding: 16px !important;
  }
  .whole h5 {
    font-size: 1rem !important;
  }
  .whole h3 {
    font-size: 2rem !important;
  }
  .whole p {
    margin-top: unset !important;
    font-size: 15px !important;
  }
  .whole a {
    margin-right: unset !important;
    float: unset !important;
  }
  .aboutimg {
    height: unset !important;
  }
  .about1 {
    margin-top: unset !important;
  }
  .about1 p {
    font-size: 15px !important;
  }
  .about2 {
    padding: 16px !important;
    margin-top: 10rem !important;
  }
  .about3 {
    padding: 16px !important;
  }
  .about4 {
    height: 100% !important;
    padding: 20px !important;
  }
  .contactimg {
    padding: unset !important;
  }
  .contact1 {
    padding: 6px !important;
  }
  .contactuu {
    padding: 0.7rem !important;
  }
  .navp {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
  .collll {
    position: static !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 900px) {
  .collll {
    position: static !important;
  }
  .hoome .col-md-2 {
    display: block !important;
    width: 100% !important;
  }
  .about4 {
    height: 100% !important;
    padding: 20px !important;
  }
  .about {
    position: static !important;
    width: 100% !important;
    margin-left: unset !important;
    padding: 30px !important;
  }
  .aboutimg {
    position: static !important;
    width: 100% !important;
    height: 90% !important;
  }
  /* .aboutimg1{
    position:absolute !important;

    margin-top: 3rem !important;
  } */
  .vision {
    padding: unset !important;
  }
  .visionimg img {
    width: 100% !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .mission {
    padding: unset !important;
  }
  .missionimg img {
    width: 100% !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .button {
    display: flex !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .button1 {
    display: flex !important;
    text-align: end !important;
    justify-content: end !important;
  }
  .hom {
    margin-left: unset !important;
  }
  .home .image {
    height: 314px !important;
  }
  .carousel-caption {
    margin-top: unset !important;
  }
  .home .carousal-item h6 {
    font-size: 8px !important;
  }

  .home .carousel-caption h3 {
    font-size: 20px !important;
  }
  .buttonnnn {
    padding: 5px !important;
    font-size: 10px !important;
  }
  .topone {
    display: none !important;
  }
  .home {
    height: unset !important;
    left: unset !important;
    position: static !important;
  }
  .homee {
    padding: unset !important;
  }
  .home1 {
    padding: 2rem !important;
  }
  .home2 {
    padding: 2rem !important;
    margin-top: unset !important;
  }
  .home3 img {
    width: 75% !important;
    height: unset !important;
  }
  .home4 {
    position: absolute !important;
    top: 103px !important;
    left: 73px !important;
  }
  .collll {
    padding: 31px !important;
  }
  .collll p {
    font-size: 1.2rem !important;
  }
  .carduu {
    width: 28% !important;
    margin-left: 0px !important;
  }
  .home9 {
    position: static !important;
    margin-left: 0px !important;
    padding: 20px !important;
  }
  .home10 img {
    position: absolute !important;
    top: 1.5rem !important;
    left: 19.5rem !important;
    width: 63% !important;
  }
  .navbar-nav {
    height: 27.7rem !important;
  }
  .navvv {
    margin-left: unset !important;
  }
  .carousel-caption {
    margin-top: 3rem;
  }
  .home1 h5 {
    font-size: 1rem !important;
  }
  .home1 h3 {
    font-size: 2rem !important;
  }
  .home2 p {
    margin-top: unset !important;
    font-size: 14px !important;
  }
  .home2 a {
    float: unset !important;
  }
  .whole {
    padding: 16px !important;
  }
  .whole h5 {
    font-size: 1rem !important;
  }
  .whole h3 {
    font-size: 2rem !important;
  }
  .whole p {
    margin-top: unset !important;
    font-size: 15px !important;
  }
  .whole a {
    margin-right: unset !important;
    float: unset !important;
  }
  .aboutimg {
    height: unset !important;
  }
  .about1 {
    margin-top: unset !important;
  }
  .about1 p {
    font-size: 15px !important;
  }
  .about2 {
    padding: 16px !important;
    margin-top: 10rem !important;
  }
  .about3 {
    padding: 16px !important;
  }
  .about4 {
    height: 100% !important;
    padding: 20px !important;
  }
  .contactimg {
    padding: unset !important;
  }
  .contact1 {
    padding: 6px !important;
  }
  .contactuu {
    padding: 0.7rem !important;
  }
  .navp {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
}
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  .contactuu {
    padding: 0.7rem !important;
  }
  .about4 {
    height: 400px !important;
    padding: 20px !important;
  }
  .about {
    position: static !important;
    width: 100% !important;
    margin-left: unset !important;
    padding: 30px !important;
  }
  .aboutimg {
    position: static !important;
    width: 100% !important;
    height: 90% !important;
  }
  .vision {
    padding: unset !important;
  }
  .visionimg img {
    width: 100% !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .mission {
    padding: unset !important;
  }
  .missionimg img {
    width: 100% !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .button {
    display: flex !important;
    text-align: start !important;
    justify-content: start !important;
  }
  .button1 {
    display: flex !important;
    text-align: end !important;
    justify-content: end !important;
  }
  .home .image {
    height: 250px !important;
  }
  .carousel-caption {
    margin-top: unset !important;
  }
  .home .carousal-item h6 {
    font-size: 8px !important;
  }

  .home .carousel-caption h3 {
    font-size: 20px !important;
  }
  .buttonnnn {
    padding: 5px !important;
    font-size: 10px !important;
  }
  .topone {
    display: none !important;
  }
  .home {
    height: unset !important;
    left: unset !important;
    position: static !important;
  }
  .homee {
    padding: unset !important;
  }
  .home1 {
    padding: 2rem !important;
  }
  .home2 {
    padding: 2rem !important;
    margin-top: unset !important;
  }
  .home3 img {
    width: 80% !important;
    height: unset !important;
  }
  .home4 {
    position: absolute !important;
    top: 161px !important;
    left: 62px !important;
  }
  .collll {
    padding: 43px !important;
    position: absolute !important;
    left: 6rem !important;
  }
  .collll p {
    font-size: 1.2rem !important;
  }
  .carduu {
    margin-left: 0px !important;
  }
  .home9 {
    position: relative !important;
    margin-left: 0px !important;
    padding: 84px !important;
  }
  .home10 img {
    position: absolute !important;
    top: 81px !important;
    left: 399px !important;
    width: 100% !important;
  }
  .navbar-nav {
    height: 9.7rem !important;
  }
  .navvv {
    margin-left: unset !important;
  }
  .carousel-caption {
    margin-top: 3rem;
  }
  .home1 h5 {
    font-size: 1rem !important;
  }
  .home1 h3 {
    font-size: 2rem !important;
  }
  .home2 p {
    margin-top: unset !important;
  }
  .home2 a {
    float: unset !important;
  }
  .whole {
    padding: 16px !important;
  }
  .whole h5 {
    font-size: 1rem !important;
  }
  .whole h3 {
    font-size: 2rem !important;
  }
  .whole p {
    margin-top: unset !important;
  }
  .whole a {
    margin-right: unset !important;
    float: unset !important;
  }
  .aboutimg {
    height: unset !important;
  }
  .about1 {
    margin-top: unset !important;
  }
  .about2 {
    padding: 16px !important;
  }
  .about2 img {
    margin-top: 4rem !important;
  }
  .about3 {
    padding: 16px !important;
  }
  .about4 {
    padding: 20px !important;
  }
  .contactimg {
    padding: unset !important;
  }
  .contact1 {
    padding: 6px !important;
  }
  .hom {
    margin-left: unset !important;
  }
  .navp {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
}
